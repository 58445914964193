import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { AddCircleRounded } from "@mui/icons-material";

import WaitingForLinkUI from "pages/BankDetails/MandateStatus/WaitingForLinkUI";
import NoData from "pages/NoData/NoData";
import MobileNav from "components/common/MobileNav/MobileNav";
import Button from "components/common/Button/Button";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import BottomSheet from "components/common/BottomSheet/BottomSheet";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS, GENERIC_SERVER_ERROR } from "utils/constants";
import { addUserDetails } from "redux/user/user-slice";

import {
  CloseButton,
  HeadingPrimary,
  HeadingPrimaryBold,
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";
import {
  BankAccountCard,
  BankAccountsCont,
  PictureHolder,
  BankDetailsCont,
  MandateCont,
  PageCont,
} from "./BankDetails.styles";
import DropdownMenu from "components/ui/DropdownMenu/DropdownMenu";
import { mapServerErrorsToLocal } from "utils/functions";
import MiniLoader from "components/common/MiniLoader/MiniLoader";

const BankDetails = () => {
  const { nextRegistrationScreen } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedBankDetails, setSelectedBankDetails] = useState(0);
  const [showBankDetails, setShowBankDetails] = useState(0);

  // MANDATE RELATED
  const [selectedToCompleteMandate, setSelectedToCompleteMandate] =
    useState(null);
  const [customMandateError, setCustomMandateError] = useState("");
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [bottomSheetData, setBottomSheetData] = useState({
    functionalityType: "",
    mandateNumber: "",
  });

  const [showChangeDefault, setShowChangeDefault] = useState(false);

  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [recentMandateId, setRecentMandateId] = useState(null);

  const [changeDefaultError, setChangeDefaultError] = useState("");

  const handleSelection = (name, index) => {
    setSelectedBankDetails(name);
    setShowBankDetails(index);
  };

  // GETTING USER DETAILS

  const getUserDetails = () => {
    return credentialAxios.get(API_ENDPOINTS.getUserInfo);
  };

  const onSuccess = (res) => {
    dispatch(addUserDetails(res.data));
    setSelectedBankDetails(res.data.bankdetail[0].account_number);
    setShowBankDetails(0);
  };

  const {
    data: userData,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery("userData", getUserDetails, {
    onSuccess,
  });

  // ADDING MANDATE

  const handleAddMandate = () => {
    return credentialAxios.post(API_ENDPOINTS.addMandate, {
      bank_id: Number(userData.data.bankdetail[showBankDetails].id),
    });
    // return true;
  };

  const onMandateAdded = (res) => {
    setRecentMandateId(res.data.mandate_id);
    setShowBottomSheet(false);
    // setRecentMandateId("18830114");
    setShowWaitingModal(true);
  };

  const onMandateAddError = (err) => {};

  const postingMandate = useMutation(handleAddMandate, {
    onSuccess: onMandateAdded,
    onError: onMandateAddError,
  });

  // GET MANDATE LINK
  const handleCompleteMandate = (mandate_id) => {
    setSelectedToCompleteMandate(mandate_id);
    return credentialAxios.post(API_ENDPOINTS.getMandateLink, {
      mandate_id,
    });
    // return true;
  };

  const onCompleteMandateResSucceed = (res) => {
    if (res.data.html_link === "Please wait for sometime.") {
      setCustomMandateError("Please wait for sometime and try again.");
      setShowWaitingModal(false);
      setRecentMandateId(null);
      return;
    }
    if (res.data.html_link) {
      // if (true) {
      window.location.replace(res.data.html_link);
      setShowWaitingModal(false);
      // window.location.replace("https://wisebulls.com/");
    }
  };

  const completeMandateRes = useMutation(handleCompleteMandate, {
    onSuccess: onCompleteMandateResSucceed,
    onError: () => {},
  });

  // CHANGE DEFAULT BANK

  const handleChangeDefaultBank = (bank_id) => {
    return credentialAxios.post(API_ENDPOINTS.changeDefaultBank, {
      bank_id,
    });
  };

  const { mutate: setAsDefaultBank, isLoading: isChangingDefault } =
    useMutation("", handleChangeDefaultBank, {
      onSuccess: () => {
        setShowChangeDefault(false);
        refetch();
      },
      onError: (err) => {
        const newErrors = mapServerErrorsToLocal(
          err,
          { general: "" },
          ["bank_id"],
          { error: "general", bank_id: "general" }
        );
        setChangeDefaultError(newErrors?.general);
      },
    });

  return (
    <>
      {showWaitingModal ? (
        <WaitingForLinkUI
          checkingLink={completeMandateRes.isLoading}
          recentMandateId={recentMandateId}
          onExpire={() => {
            completeMandateRes.mutate(recentMandateId);
          }}
          onContinueLater={() => setShowWaitingModal(false)}
        />
      ) : null}
      {isLoading || isRefetching ? (
        <></>
      ) : (
        <>
          <MobileNav headerTitle={"Bank Details"} />

          {userData.data.bankdetail.length === 0 ? (
            <NoData
              message={"You have not added bank account yet"}
              redirecting="Complete Registration Setup"
              onRedirect={() => navigate(nextRegistrationScreen)}
            />
          ) : (
            <>
              <HeadingPrimary style={{ marginBottom: "2rem" }}>
                <HeadingPrimaryBold>Bank Account Details</HeadingPrimaryBold>
              </HeadingPrimary>
              <PageCont style={{}}>
                <BankAccountsCont>
                  {userData.data?.bankdetail.map((bank, index) => {
                    return (
                      <BankAccountCard
                        active={selectedBankDetails === bank.account_number}
                        key={index}
                        onClick={() =>
                          handleSelection(bank.account_number, index)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <PictureHolder />
                          <div>
                            <p
                              style={{
                                fontSize: "1.6rem",
                                fontWeight: "600",
                              }}
                            >
                              {bank.bank_name}
                            </p>
                            {bank.default ? (
                              <p style={{ color: "var(--themeColor)" }}>
                                Primary bank
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <StyledAlignCenter>
                          <p
                            style={{
                              fontSize: "1.6rem",
                              color: "var(--text-grey)",
                            }}
                          >{`${"X".repeat(
                            userData.data.bankdetail[index].account_number
                              .length - 4
                          )}${userData.data.bankdetail[
                            index
                          ].account_number.substr(
                            userData.data.bankdetail[index].account_number
                              .length - 4
                          )}`}</p>
                          {!bank.default ? (
                            <DropdownMenu
                              options={[
                                {
                                  label: "Set as Primary bank",
                                  functionality: () => {
                                    setAsDefaultBank(
                                      userData.data.bankdetail[index].id
                                    );
                                    setShowChangeDefault(true);
                                  },
                                },
                              ]}
                            />
                          ) : null}
                        </StyledAlignCenter>
                      </BankAccountCard>
                    );
                  })}

                  <BankAccountCard
                    onClick={() =>
                      navigate(`/registration/select-bank`, {
                        state: {
                          fromLocation: "/bank-details",
                          nonReg: true,
                        },
                      })
                    }
                    noHover={true}
                    style={{
                      borderBottomLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                      }}
                    >
                      <AddCircleRounded
                        style={{
                          fontSize: "2.4rem",
                          color: "var(--themeColor)",
                        }}
                      />

                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "500",
                          color: "var(--themeColor)",
                        }}
                      >
                        Add another Bank
                      </span>
                    </div>
                  </BankAccountCard>
                </BankAccountsCont>

                <BankDetailsCont>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      marginBottom: "4rem",
                      padding: "3rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <PictureHolder dimension={6} />
                    <div>
                      <p style={{ fontSize: "2rem", fontWeight: "600" }}>
                        {userData.data.bankdetail[showBankDetails]?.bank_name}
                      </p>
                      {userData.data.bankdetail[showBankDetails]?.default ? (
                        <p style={{ color: "var(--themeColor)" }}>
                          Primary bank
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8rem",
                      padding: "3rem",
                      paddingTop: 0,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4.5rem",
                        color: "var(--text-grey)",
                        fontSize: "1.6rem",
                      }}
                    >
                      <p>Account</p>
                      <p>IFSC Code</p>
                      <p>Branch Name</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4.5rem",
                        color: "",
                        fontSize: "1.6rem",
                        fontWeight: "500",
                      }}
                    >
                      <p>
                        {`${"X".repeat(
                          userData.data.bankdetail[showBankDetails]
                            .account_number.length - 4
                        )}${userData.data.bankdetail[
                          showBankDetails
                        ].account_number.substr(
                          userData.data.bankdetail[showBankDetails]
                            .account_number.length - 4
                        )}`}
                      </p>
                      <p>
                        {userData.data.bankdetail[showBankDetails].branch_ifsc}
                      </p>
                      <p>
                        {userData.data.bankdetail[showBankDetails].branch_name}
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "2rem" }}>
                    <Button
                      onClick={() => {
                        setBottomSheetData({
                          functionalityType: "add",
                          mandateNumber: null,
                        });
                        setShowBottomSheet(true);
                      }}
                      name={"ADD MANDATE"}
                      style={{ width: "100%" }}
                      loading={postingMandate.isLoading}
                      loadingText={"ADDING MANDATE"}
                    />
                  </div>

                  {userData.data.bankdetail[showBankDetails].mandate.length !==
                    0 && (
                    <p
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        margin: "3rem",
                      }}
                    >
                      Activate Autopay mandates
                    </p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {userData.data.bankdetail[showBankDetails].mandate.map(
                      (curr, i) => {
                        return (
                          <>
                            <div key={i}>
                              <MandateCont>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4.5rem",
                                    color: "var(--text-grey)",
                                    fontSize: "1.6rem",
                                  }}
                                >
                                  <p>Mandate ID</p>
                                  <p>Created At</p>
                                  <p>Status</p>
                                  <p>Amount</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4.5rem",
                                    color: "",
                                    fontSize: "1.6rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  <p>{curr.id}</p>
                                  <p>{new Date(curr.created).toDateString()}</p>
                                  <p>
                                    {curr.status_comment
                                      ? curr.status_comment
                                      : curr.status}
                                  </p>
                                  <p>{curr.amount}</p>
                                </div>
                              </MandateCont>
                              {(curr.status === "Registered in BSE" ||
                                curr.status === "Received by BSE" ||
                                curr.status === "Form submitted to BSE") && (
                                <div style={{ padding: "2rem" }}>
                                  <Button
                                    style={{ width: "100%" }}
                                    name={"COMPLETE MANDATE"}
                                    // onClick={() => completeMandateRes.mutate(i)}
                                    onClick={() => {
                                      setBottomSheetData({
                                        functionalityType: "complete",
                                        mandateNumber: curr.id,
                                      });
                                      setShowBottomSheet(true);
                                    }}
                                    loading={
                                      i === selectedToCompleteMandate
                                        ? completeMandateRes.isLoading
                                        : false
                                    }
                                    loadingText={"REDIRECTING"}
                                  />
                                  {i === selectedToCompleteMandate ? (
                                    <ErrorMessage
                                      show={
                                        completeMandateRes.isError ||
                                        customMandateError
                                      }
                                      message={
                                        customMandateError ||
                                        GENERIC_SERVER_ERROR
                                      }
                                      variant={"background"}
                                    />
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </BankDetailsCont>
              </PageCont>
            </>
          )}
          <BottomSheet
            containerStyles={{ padding: "3rem 2rem" }}
            show={showBottomSheet}
            close={() => {
              setShowBottomSheet(false);
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  marginBottom: "6px",
                }}
              >
                Proceed with{" "}
                {bottomSheetData.functionalityType === "add"
                  ? "adding"
                  : "completing"}{" "}
                mandate?
              </p>
              {bottomSheetData.functionalityType === "add" ? (
                <p>
                  Once the mandate is added it will be listed under "Activate
                  Autopay Mandates"
                </p>
              ) : null}
              <StyledAlignCenter style={{ gap: "2rem", marginTop: "1.8rem" }}>
                <Button
                  name="YES"
                  onClick={
                    bottomSheetData.functionalityType === "add"
                      ? () => {
                          setShowBottomSheet(false);
                          postingMandate.mutate();
                        }
                      : () => {
                          setShowBottomSheet(false);
                          completeMandateRes.mutate(
                            bottomSheetData.mandateNumber
                          );
                        }
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                  contStyle={{ width: "100%" }}
                />

                <Button
                  onClick={() => setShowBottomSheet(false)}
                  name="NO"
                  style={{ width: "100%" }}
                  contStyle={{ width: "100%" }}
                />
              </StyledAlignCenter>
            </div>
          </BottomSheet>
          <BottomSheet
            containerStyles={{ padding: "3rem 2rem" }}
            show={showChangeDefault}
            close={() => {
              setShowChangeDefault(false);
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "2rem",
              }}
            >
              {isChangingDefault ? (
                <StyledCentering
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <p>Please wait...</p>
                  <MiniLoader />
                </StyledCentering>
              ) : null}
              {changeDefaultError ? (
                <div>
                  <CloseButton
                    onClick={() => {
                      setChangeDefaultError("");
                      setShowChangeDefault(false);
                    }}
                  />
                  <p>{changeDefaultError}</p>
                </div>
              ) : null}
            </div>
          </BottomSheet>
        </>
      )}
    </>
  );
};

export default BankDetails;
