import React from "react";

import { AnimatePresence } from "framer-motion";

import Explore from "pages/Explore/Explore";

import { Route, Routes, useLocation } from "react-router-dom";

import SingleMutualFund from "pages/SingleMutualFund/SingleMutualFund";

import MutualFundsFilter from "pages/MutualFundsFilter/MutualFundsFilter";

import Payment from "pages/Payment/Payment";

import Investments from "pages/Investments/Investments";

import Orders from "pages/Orders/Orders";
import Help from "pages/Help/Help";
import Account from "pages/mobile/Account/Account";
import Search from "pages/mobile/Search/Search";
import InvestMore from "pages/mobile/InvestMore/InvestMore";
import Redeem from "pages/mobile/Redeem/Redeem";
import NoData from "pages/NoData/NoData";
import notFoundImg from "assets/banner/404-Error-bro.svg";
import ProtectedRoute from "utils/ProtectedRoute";

import BankDetails from "pages/BankDetails/BankDetails";
import PaymentSuccess from "pages/Payment/PaymentSuccess";
import PaymentFailed from "pages/Payment/PaymentFailed";
import MandateStatus from "pages/BankDetails/MandateStatus/MandateStatus";
import Login from "pages/Login/Login";
import Register from "pages/Register/Register";
import UpiTransactionTimer from "pages/UpiTransactionTimer/UpiTransactionTimer";
import PaymentWeb from "pages/PaymentWeb/PaymentWeb";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import ConfirmOrder from "pages/ConfirmOrder/ConfirmOrder";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import DeleteAccount from "pages/DeleteAccount/DeleteAccount";
import OrderDetails from "pages/OrderDetails/OrderDetails";
import WaitingForLinkUI from "pages/BankDetails/MandateStatus/WaitingForLinkUI";

import Registration from "pages/registration/Registration";
import AddPhoneNumber from "pages/registration/AddPhoneNumber/AddPhoneNumber";
import OtpVerification from "pages/registration/OtpVerifcation/OtpVerification";
import VerifyPan from "pages/registration/VerifyPan/VerifyPan";
import AddPersonalDetails from "pages/registration/AddPersonalDetails/AddPersonalDetails";
import AddProfessionalDetails from "pages/registration/AddProfessionalDetails/AddProfessionalDetails";
import SelectBank from "pages/registration/SelectBank/SelectBank";
import SelectBranch from "pages/registration/SelectBranch/SelectBranch";
import AddBank from "pages/registration/AddBank/AddBank";
import AddNominee from "pages/registration/AddNominee/AddNominee";
import AddAddress from "pages/registration/AddAddress/AddAddress";
import AddSignature from "pages/registration/AddSignature/AddSignature";
import ConfirmDetails from "pages/registration/ConfirmDetails/ConfirmDetails";
import ConfirmKycOtp from "pages/registration/ConfirmKycOtp/ConfirmKycOtp";
import RegistrationSuccess from "pages/registration2/RegistrationSuccess/RegistrationSuccess";
import OnBoardStatus from "pages/RedirectStatuses/OnBoardStatus";
import AuthenticatedUserNotAllowed from "utils/AuthenticatedUserNotAllowed";
import NetBankingPaymentRedirect from "pages/Payment/NetBankingPaymentRedirect";
import MobileInvest from "pages/SingleMutualFund/_MobileInvest";
import Test from "pages/Test/Test";
import Collections from "pages/Collections/Collections";
import TopPerformingFunds from "pages/Collections/TopPerformingFunds";
import ConsistentPerformingFunds from "pages/Collections/ConsistentPerformingFunds";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/registration" element={<Registration />}>
          <Route path="add-phone-number" element={<AddPhoneNumber />} />
          <Route path="otp-verification" element={<OtpVerification />} />
          <Route path="verify-pan" element={<VerifyPan />} />
          <Route path="add-personal-details" element={<AddPersonalDetails />} />
          <Route
            path="add-professional-details"
            element={<AddProfessionalDetails />}
          />
          <Route path="select-bank" element={<SelectBank />} />
          <Route path="select-branch" element={<SelectBranch />} />
          <Route path="add-bank" element={<AddBank />} />
          <Route path="add-nominee" element={<AddNominee />} />
          <Route path="add-address" element={<AddAddress />} />
          <Route path="add-signature" element={<AddSignature />} />
          <Route path="confirm-details" element={<ConfirmDetails />} />
          <Route path="confirm-kyc-otp" element={<ConfirmKycOtp />} />
        </Route>
        <Route path="/collections" element={<Collections />}>
          <Route
            path="consistent-performing-funds"
            element={<ConsistentPerformingFunds />}
          />
          <Route path="top-performing-funds" element={<TopPerformingFunds />} />
          <Route
            path="large-cap"
            element={<TopPerformingFunds category="Equity: Large Cap" />}
          />
          <Route
            path="mid-cap"
            element={<TopPerformingFunds category="Equity: Mid Cap" />}
          />
          <Route
            path="small-cap"
            element={<TopPerformingFunds category="Equity: Small Cap" />}
          />
          {/* <Route path="top-sip-funds" element={<TopSIPFunds />} />
          <Route path="top-lumpsum-funds" element={<TopSIPFunds lumpsum />} /> */}
        </Route>
        <Route
          path="/registration-successful"
          element={
            <ProtectedRoute>
              <RegistrationSuccess />
            </ProtectedRoute>
          }
        />

        {/* MUTUAL FUNDS */}
        <Route path="/" element={<Explore />} />
        <Route path="/mutual-funds/:mfId" element={<SingleMutualFund />} />
        <Route path="/mutual-funds" element={<MutualFundsFilter />} />
        {/* GENERAL */}

        <Route
          path="/mutual-funds/invest"
          element={
            <ProtectedRoute>
              <MobileInvest />
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={
            <AuthenticatedUserNotAllowed>
              <Login />
            </AuthenticatedUserNotAllowed>
          }
        />
        <Route
          path="/register"
          element={
            <AuthenticatedUserNotAllowed>
              <Register />
            </AuthenticatedUserNotAllowed>
          }
        />
        <Route path="/help" element={<Help />} />
        <Route path="/search" element={<Search />} />

        {/************* PROTECTED ROUTES **************/}
        {/* USER */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset" element={<ResetPassword />} />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/investments"
          element={
            <ProtectedRoute>
              <Investments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/:orderNumber"
          element={
            <ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bank-details"
          element={
            <ProtectedRoute>
              <BankDetails />
            </ProtectedRoute>
          }
        />
        {/* NOT FOUND */}

        {/* Payment RELATED */}
        <Route
          path="/mutual-funds/:mfName/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invest-more"
          element={
            <ProtectedRoute>
              <InvestMore />
            </ProtectedRoute>
          }
        />
        <Route
          path="/redeem"
          element={
            <ProtectedRoute>
              <Redeem />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-failed"
          element={
            <ProtectedRoute>
              <PaymentFailed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm-order"
          element={
            <ProtectedRoute>
              <ConfirmOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upi-transaction-timer"
          element={
            <ProtectedRoute>
              <UpiTransactionTimer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-web"
          element={
            <ProtectedRoute>
              <PaymentWeb />
            </ProtectedRoute>
          }
        />
        {/* MADATE RELATED */}
        <Route
          path="/mandate-status"
          element={
            <ProtectedRoute>
              <MandateStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm_order"
          element={
            <ProtectedRoute>
              <NetBankingPaymentRedirect />
            </ProtectedRoute>
          }
        />
        <Route
          path="/waiting-for-link"
          element={
            <ProtectedRoute>
              <WaitingForLinkUI />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/delete-account"
          element={
            <ProtectedRoute>
              <DeleteAccount />
            </ProtectedRoute>
          }
        />
        <Route path="/test" element={<Test />} />
        {/* ASSETS LINKS JSON */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {/* REDIRECT STATUSES */}
        <Route path="/onboard-status" element={<OnBoardStatus />} />
        <Route
          path="*"
          element={
            <NoData
              message={"Lost your way?"}
              subMessage={"Sorry, we can't find the page you are looking for."}
              imageSrc={notFoundImg}
            />
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
