import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import facebookLogo from "assets/logos/icons8-facebook.svg";
import whatsappLogo from "assets/logos/icons8-whatsapp.svg";
import mailLogo from "assets/logos/icons8-mail.svg";
import linkedInLogo from "assets/logos/icons8-linkedin.svg";
import twitterLogo from "assets/logos/icons8-twitter.svg";

import { CloseButton, SubHeading } from "styles/common-styled-components";
import {
  ShareButton,
  ShareCont,
  ShareLogo,
  ShareMainCont,
} from "./SingleMutualFund.styles";

import InputField from "components/common/InputField/InputField";
const ShareComp = ({ name, closeModal }) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <ShareMainCont style={{}}>
        <div>
          <SubHeading style={{ marginBottom: 0 }}>
            Share this Mutual Fund link
          </SubHeading>
          <p style={{ fontSize: "1.2rem" }}>({name})</p>
        </div>

        <CloseButton onClick={closeModal} />
      </ShareMainCont>
      <p style={{ padding: "1rem 2rem" }}>Share this link via</p>

      <ShareCont>
        <WhatsappShareButton url={window.location.href}>
          <ShareButton>
            <ShareLogo src={whatsappLogo} alt="whatsapp logo" />
          </ShareButton>
        </WhatsappShareButton>

        <EmailShareButton url={window.location.href}>
          <ShareButton>
            <ShareLogo src={mailLogo} alt="mail logo" />
          </ShareButton>
        </EmailShareButton>

        <FacebookShareButton url={window.location.href}>
          <ShareButton>
            <ShareLogo src={facebookLogo} alt="facebook logo" />
          </ShareButton>
        </FacebookShareButton>

        <LinkedinShareButton url={window.location.href}>
          <ShareButton>
            <ShareLogo src={linkedInLogo} alt="linked in logo" />
          </ShareButton>
        </LinkedinShareButton>

        <TwitterShareButton url={window.location.href}>
          <ShareButton>
            <ShareLogo src={twitterLogo} alt="twitter logo" />
          </ShareButton>
        </TwitterShareButton>
      </ShareCont>
      <p style={{ padding: "1rem 2rem" }}>Or Copy link</p>
      <div style={{ padding: "1rem 2rem" }}>
        <CopyToClipboard
          text={window.location.href}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 10000);
          }}
        >
          <ShareButton>
            <InputField
              style={{ borderRadius: "100px" }}
              contStyles={{ width: "80%" }}
              defaultValue={window.location.href}
              readonly
            />
            {copied ? <span>Copied</span> : <span>Copy</span>}
          </ShareButton>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default ShareComp;
