import React from "react";

import {
  Container,
  ModalLeft,
  ModalLogo,
  ModalRight,
} from "./LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  CloseButton,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";

import RegisterForm from "components/forms/RegisterForm";

const RegisterModalContent = ({ closeModal = () => {} }) => {
  return (
    <Container>
      <ModalLeft>
        <ModalLogo src={logo} />

        <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
          <HeadingPrimary color="#f7f8fa">
            Investing in{" "}
            <HeadingPrimaryBold color="#f7f8fa">
              Mutual Funds simplified
            </HeadingPrimaryBold>
          </HeadingPrimary>
        </div>
      </ModalLeft>

      <ModalRight>
        <div style={{ width: "100%" }}>
          <HeadingCont>
            <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
              <CloseButton onClick={closeModal} />
            </div>
          </HeadingCont>
          <RegisterForm closeModal={closeModal} />
        </div>
      </ModalRight>
    </Container>
  );
};

export default RegisterModalContent;
