import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import {
  ArrowBack,
  Close,
  History,
  Search,
  TrendingUp,
} from "@mui/icons-material";
import SyncLoader from "react-spinners/SyncLoader";

import { API_ENDPOINTS } from "utils/constants";
import { publicAxios } from "utils/api-calls";
import {
  SearchBar,
  SearchBarCont,
  SearchResultsCont,
  SearchCard,
  SearchLoadingCont,
  NoResultCont,
  Container,
  RecentHistoryCard,
} from "./SearchMutualFunds.styles";
import useOutsideClick from "hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import {
  DesktopView,
  MobileView,
  StyledAlignCenter,
} from "styles/common-styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addSearch } from "redux/recent-search/recent-search-slice";

const SearchMutualFunds = ({ isMobileSearch }) => {
  const dispatch = useDispatch();
  const searchResultsRef = useRef(null);
  const navigation = useNavigate();
  const recentHistory = useSelector((state) => state.search.schemes);

  // STATE

  const [searchInput, setSearchInput] = useState("");
  const [showSearchResultsCont, setShowSearchResultsCont] = useState(
    isMobileSearch ? true : false
  );

  //   API CALLS

  const searchMutualFunds = (text) => {
    return publicAxios.get(API_ENDPOINTS.autocompleteMutualFund, {
      params: {
        scheme_name: text,
      },
    });
  };

  //   QUERY

  const { isLoading, data, refetch } = useQuery(
    ["searchResults", searchInput],
    () => searchMutualFunds(searchInput),
    { refetchOnWindowFocus: false }
  );

  //   USE EFFECT

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useOutsideClick(searchResultsRef, () => {
    if (isMobileSearch) {
      return;
    }
    setShowSearchResultsCont(false);
  });

  return (
    <Container ref={searchResultsRef}>
      <SearchBarCont
        activeSearchBar={showSearchResultsCont ? true : false}
        onClick={() => setShowSearchResultsCont(true)}
      >
        <MobileView>
          <ArrowBack
            style={{ fontSize: "2rem" }}
            onClick={() => navigation(-1)}
          />
        </MobileView>
        <DesktopView>
          <Search style={{ fontSize: "2.4rem", color: "var(--mediumGrey)" }} />
        </DesktopView>

        <SearchBar
          value={searchInput}
          placeholder="Search For Mutual Fund"
          onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
        />
        {searchInput ? (
          <Close
            style={{ fontSize: "2rem", cursor: "pointer" }}
            onClick={() => setSearchInput("")}
          />
        ) : null}
      </SearchBarCont>
      {showSearchResultsCont ? (
        <>
          <SearchResultsCont
            activeSearchBar={showSearchResultsCont ? true : false}
          >
            {recentHistory.map((curr, i) => {
              return (
                <RecentHistoryCard
                  key={i}
                  onClick={() => {
                    setSearchInput(curr.name);
                    setShowSearchResultsCont(false);
                    navigation(`/mutual-funds/${curr.id}`);
                    dispatch(
                      addSearch({
                        name: curr.name,
                        id: curr.id,
                      })
                    );
                  }}
                >
                  <StyledAlignCenter style={{ gap: "1rem" }}>
                    <History />
                    <p style={{ fontSize: "1rem" }}>{curr.name}</p>
                  </StyledAlignCenter>
                  {/* <CloseButton style={{ fontSize: "1rem" }} radius="2rem" /> */}
                </RecentHistoryCard>
              );
            })}
            {isLoading ? (
              <SearchLoadingCont className="centering">
                <SyncLoader
                  color={"var(--themeColor)"}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </SearchLoadingCont>
            ) : (
              <div>
                {data.data.map((curr, i) => {
                  return (
                    <SearchCard
                      key={i}
                      onClick={() => {
                        setSearchInput(curr.name);
                        setShowSearchResultsCont(false);
                        navigation(`/mutual-funds/${curr.id}`);
                        dispatch(
                          addSearch({
                            name: curr.name,
                            id: curr.id,
                          })
                        );
                      }}
                    >
                      <TrendingUp />
                      <p>{curr.name}</p>
                    </SearchCard>
                  );
                })}
                {data.data.length === 0 && (
                  <NoResultCont style={{ cursor: "default" }}>
                    No Result Found for {searchInput}
                  </NoResultCont>
                )}
              </div>
            )}
          </SearchResultsCont>
        </>
      ) : null}
    </Container>
  );
};

export default SearchMutualFunds;
