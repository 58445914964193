import React from "react";
import styled from "styled-components/macro";
import logo from "assets/logos/logo.png";

const links = [
  [
    "PRODUCTS",
    "Stocks",
    "Future and Options",
    "Mutual Funds",
    "Fixed Deposit",
    "US Stocks",
  ],
  [
    "QUICK LINKS",
    "Caluclators",
    "Glossary",
    "Open Demat Account",
    "Site Map",
    "Privacy Policy",
    "Terms & Conditions",
  ],
  [
    "WISEBULLS",
    "About Us",
    "Pricing",
    "Blog",
    "Media & Press",
    "Careers",
    "Help & Support",
  ],
  [
    "CONTACT INFO",
    "No.24 2nd floor, Green Park",
    "Road, South Avenue",
    "Delhi - 12345",
    "+91 1234 5678 90",
    "support@wisebulls.com",
  ],
];

const mobileLinks = [
  [
    "PRODUCTS",
    "Stocks",
    "Future and Options",
    "Mutual Funds",
    "Fixed Deposit",
    "US Stocks",
  ],

  [
    "CONTACT INFO",
    "No.24 2nd floor, Green Park",
    "Road, South Avenue",
    "Delhi - 12345",
    "+91 1234 5678 90",
    "support@wisebulls.com",
  ],
];
const Container = styled.div`
  margin-top: 7rem;
  padding: 2rem 7rem;
  background-color: #f7f8fa;

  @media (max-width: 980px) {
    padding: 7rem 3rem;
  }
`;

const TopLogoCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
`;

const TopLogo = styled.img`
  width: 10rem;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 980px) {
    display: none;
  }
`;
const LinksContainerMobile = styled.div`
  display: none;

  @media (max-width: 980px) {
    display: flex;
    justify-content: space-between;
  }
`;

const List = styled.ul`
  display: ${({ grid }) => (grid ? "" : "flex")};
  flex-direction: column;
  gap: 1.6rem;
  /* flex: 1; */
`;

const ListItem = styled.li`
  list-style: none;
  font-size: ${({ heading }) => (heading ? "1.6rem" : "1.3rem")};
  font-weight: ${({ heading }) => (heading ? "800" : "600")};
  color: ${({ heading }) => (heading ? "#304666" : "#8895a7")};
  cursor: pointer;
  margin-top: ${({
    contactMarginTop,
    contactMarginTop2,
    contactMarginTop3,
  }) => {
    if (contactMarginTop) {
      return "1.6rem";
    }
    if (contactMarginTop2) {
      return "3.2rem";
    }
    if (contactMarginTop3) {
      return "1.6rem";
    }
  }};
  margin-bottom: ${({ contactMarginBottom }) =>
    contactMarginBottom ? "0.6rem" : "0"};
`;

const FooterCopyRight = styled.div`
  background-color: #e5ebf3;
  font-weight: 600;
  font-style: italic;
  font-size: 1.4rem;
  padding: 2.4rem 2rem;
  text-align: center;
  margin-top: 10rem;
`;

const Footer = () => {
  return (
    <>
      {/* <Container>
        <TopLogoCont>
          <TopLogo src={logo} />
        </TopLogoCont>
        <LinksContainer>
          {links.map((arr, index) => {
            return (
              <List key={index} grid={index === 3 ? true : false}>
                {arr.map((link, index2) => {
                  return (
                    <ListItem
                      heading={index2 === 0 ? true : false}
                      contactMarginTop={
                        index === 3 && index2 === 1 ? true : false
                      }
                      contactMarginTop2={
                        index === 3 && index2 === 4 ? true : false
                      }
                      contactMarginTop3={
                        index === 3 && index2 > 4 ? true : false
                      }
                      key={index2}
                      contactMarginBottom={
                        index === 3 && index2 > 0 && index2 < 4 ? true : false
                      }
                    >
                      {link}
                    </ListItem>
                  );
                })}
              </List>
            );
          })}
        </LinksContainer>

        <LinksContainerMobile>
          {mobileLinks.map((arr, index) => {
            return (
              <List key={index} grid={index === 3 ? true : false}>
                {arr.map((link, index2) => {
                  return (
                    <ListItem
                      heading={index2 === 0 ? true : false}
                      contactMarginTop={
                        index === 3 && index2 === 1 ? true : false
                      }
                      contactMarginTop2={
                        index === 3 && index2 === 4 ? true : false
                      }
                      contactMarginTop3={
                        index === 3 && index2 > 4 ? true : false
                      }
                      key={index2}
                      contactMarginBottom={
                        index === 3 && index2 > 0 && index2 < 4 ? true : false
                      }
                    >
                      {link}
                    </ListItem>
                  );
                })}
              </List>
            );
          })}
        </LinksContainerMobile>
      </Container> */}

      <FooterCopyRight>
        <p style={{ fontStyle: "normal" }}>Disclaimer</p>
        <p style={{ fontStyle: "normal", marginBottom: "2rem" }}>
          Mutual Fund investments are subject to market risks. Please read all
          scheme related documents carefully before investing. Past performance
          is not an indicator of future returns.
        </p>
        <p>
          Copyright {new Date().getFullYear()} @ wisebulls.com | All Rights
          Reserved
        </p>
      </FooterCopyRight>
    </>
  );
};

export default Footer;

{
  /* <div
            style={{
              flex: 1,
              padding: "0 2rem",
              paddingRight: "4rem",
              fontSize: "1.4rem",
            }}
          >
            <h1 style={{ marginBottom: "2rem" }}>About us</h1>
            <p>
              Wisebulls is a mutual fund investing platform where users can find
              the best mutual funds to invest in and can invest their money
              without any hassles. Wisebulls provides objective evaluation of
              mutual funds and does not advice or recommend any mutual fund or
              portfolios. Investor shall invest with their own descretion.
              Wisebulls does not guarantee any returns and safety of capital.
            </p>
          </div> */
}
