import React, { useContext } from "react";
import OtpInput from "react-otp-input";
import { useState } from "react";
import {
  StyledCentering,
  UnderLineButton,
} from "styles/common-styled-components";
import Button from "components/common/Button/Button";
import MobileNav from "components/common/MobileNav/MobileNav";
import { useLocation, useNavigate } from "react-router-dom";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { useMutation } from "react-query";
import { ErrorMessage } from "styles/common-styled-components";
import Timer from "components/common/Timer/Timer";
import Note from "components/common/Note/Note";
import { AlertContext } from "App";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import { mapServerErrorsToLocal } from "utils/functions";

const ConfirmOrder = () => {
  const initialErrorState = { general: "", otp: "" };
  const resendInitialErrorState = { general: "" };

  const { state: accumulatedPayload } = useLocation();
  const { showMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [otpValue, setOtpValue] = useState(null);
  const [verifyOtpErrors, setVerifyOtpErrors] = useState(initialErrorState);

  const [showResendOtp, setShowResendOtp] = useState(false);
  const [resendOtpErrors, setResendOtpErrors] = useState(
    resendInitialErrorState
  );

  const getPayload = (resending_otp = false) => {
    let tempData = accumulatedPayload;

    tempData["otp_required"] = resending_otp;
    tempData["otp"] = resending_otp ? "" : otpValue;

    return tempData;
  };

  const initiateTransaction = () => {
    const payload = getPayload();

    return credentialAxios.post(
      accumulatedPayload.start_date
        ? API_ENDPOINTS.createSipTransaction
        : API_ENDPOINTS.createOneTimeTransaction,
      payload
    );
  };

  const onSuccess = (res) => {
    setVerifyOtpErrors(initialErrorState);
    if (accumulatedPayload.payment_option === 1 && res.data.request_sent) {
      navigate("/upi-transaction-timer", {
        state: {
          order_id: res.data.order_id,
          amount: accumulatedPayload.amount,
        },
        replace: true,
      });
    } else {
      const html = res.data.bank_html;
      navigate("/payment-web", {
        state: {
          html,
        },
        replace: true,
      });
    }
  };

  const onError = (error) => {
    const newErrors = mapServerErrorsToLocal(
      error,
      initialErrorState,
      ["otp"],
      { error: "otp" }
    );
    setVerifyOtpErrors(newErrors);
  };

  const { mutate, isLoading } = useMutation(initiateTransaction, {
    onError,
    onSuccess,
  });

  const validateOtp = () => {
    if (!otpValue) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "OTP is required",
        };
      });
      return;
    } else if (otpValue.length < 6) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "Enter all digits",
        };
      });
      return;
    }
    mutate();
  };

  const resendOtp = () => {
    const payload = getPayload(true);

    return credentialAxios.post(
      accumulatedPayload.start_date
        ? API_ENDPOINTS.createSipTransaction
        : API_ENDPOINTS.createOneTimeTransaction,
      payload
    );
  };

  const onResendSuccess = () => {
    setResendOtpErrors(resendInitialErrorState);
    setShowResendOtp(false);
    showMessage("OTP sent Successfully");
  };

  const onResendError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, resendInitialErrorState);
    setResendOtpErrors(newErrors);
  };
  const resendOtpRes = useMutation(resendOtp, {
    onSuccess: onResendSuccess,
    onError: onResendError,
  });
  return (
    <>
      <MobileNav headerTitle={"Confirm Order"} />{" "}
      <StyledCentering style={{ width: "100%" }}>
        <StyledCentering style={{ maxWidth: "34rem" }}>
          <StyledCentering
            style={{
              flexDirection: "column",
            }}
          >
            <Note note={"Please do not refresh or close this page"} />
            <p style={{ fontSize: "1.6rem", fontWeight: "800" }}>
              Verification Code
            </p>
            <p style={{ margin: "1rem 0", textAlign: "center" }}>
              We have sent an verification code to your registered mobile
              number. Please submit for confirming your order.
            </p>

            <OtpInput
              focusStyle={{
                outlineColor: "var(--themeColor)",
              }}
              shouldAutoFocus={true}
              value={otpValue}
              onChange={(otp) => setOtpValue(otp)}
              numInputs={6}
              inputStyle={{
                width: "5rem",
                height: "5rem",
                borderRadius: "6px",
                border: `1px solid ${
                  verifyOtpErrors.otp ? "var(--danger)" : "#ccc"
                }`,
                fontSize: "2.4rem",
                fontWeight: "800",
                color: "var(--themeColor)",
              }}
              containerStyle={{
                display: "flex",
                justifyContent: "space-between",
                margin: "2rem 0",
                gap: "1rem",
                width: "100%",
              }}
            />
            {verifyOtpErrors.otp && (
              <ErrorMessage>{verifyOtpErrors.otp}</ErrorMessage>
            )}
            <div style={{ width: "100%" }}>
              {showResendOtp ? (
                resendOtpRes.isLoading ? (
                  <MiniLoader />
                ) : (
                  <UnderLineButton
                    onClick={resendOtpRes.mutate}
                    style={{ marginBottom: "1rem", display: "inline-block" }}
                  >
                    Resend OTP
                  </UnderLineButton>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <span>Resend OTP in&nbsp;</span>

                  <Timer
                    numberOfMinutes={5}
                    onExpire={() => setShowResendOtp(true)}
                  />
                </div>
              )}
              {resendOtpErrors.general && (
                <ErrorMessage>{resendOtpErrors.general}</ErrorMessage>
              )}
            </div>

            <Button
              name={"CONFIRM ORDER"}
              contStyle={{ width: "100%" }}
              style={{ width: "100%" }}
              loading={isLoading}
              loadingText={"REDIRECTING"}
              onClick={validateOtp}
            />

            {verifyOtpErrors.general && (
              <ErrorMessage>{verifyOtpErrors.general}</ErrorMessage>
            )}
          </StyledCentering>
        </StyledCentering>
      </StyledCentering>
    </>
  );
};

export default ConfirmOrder;
