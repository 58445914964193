import React, { useRef, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { publicAxios, credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import {
  getNextRegistrationScreen,
  mapServerErrorsToLocal,
} from "utils/functions";
import {
  addKycInfo,
  addNextRegistrationScreen,
  addUser,
  addUserDetails,
} from "redux/user/user-slice";

import { Form } from "styles/common-styled-components";
import { useNavigate } from "react-router-dom";

import {
  HeadingPrimaryBold,
  Paragraph,
  StyledLink,
  UnderLineButton,
} from "styles/common-styled-components";

const RegisterForm = ({
  fromLocation = "/registration/add-phone-number",
  closeModal = () => {},
}) => {
  const initialErrorState = { name: "", email: "", password: "", general: "" };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password too short! Must be at least 8 characters."),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password too short! Must be at least 8 characters.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const navigate = useNavigate();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(initialErrorState);
  const [isRegistering, setIsRegistering] = useState(false);

  // API CALLS

  const handleRegister = async (payload) => {
    try {
      setIsRegistering(true);

      await publicAxios.post(API_ENDPOINTS.createUser, {
        name: payload.name,
        email: payload.email,
        password: payload.password,
      });

      setErrors(initialErrorState);
      console.log(`------ Registration success ------`);

      const loginRes = await publicAxios.post(API_ENDPOINTS.loginTokenRequest, {
        email: payload.email,
        password: payload.password,
      });

      console.log(`------ Login success: Setting auth-checked to True ------`);

      sessionStorage.setItem("auth-checked", true);
      dispatch(addUser(loginRes.data.token));

      // Fetch user info
      const userInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getUserInfo
      );

      console.log(`------ Setting user-info for auth ------`);
      dispatch(addUserDetails(userInfoResponse.data));

      // Fetch KYC info
      const kycInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getKycData
      );

      dispatch(addKycInfo(kycInfoResponse.data));

      const nextRegistrationScreen = getNextRegistrationScreen(
        userInfoResponse.data,
        kycInfoResponse.data
      );
      dispatch(addNextRegistrationScreen(nextRegistrationScreen));

      navigate(fromLocation, { replace: true });

      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsRegistering(false);
      const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
        "email",
        "password",
        "name",
      ]);
      setErrors(newErrors);
    } finally {
      console.log("Finally block");
    }
  }; ///// API CALL END

  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          email: "",
          password: "",
          name: "",
          confirmPassword: "",
        }}
        onSubmit={handleRegister}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors: formikErrors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <InputField
                label={"Name"}
                value={values.name}
                onChange={handleChange("name")}
                placeholder={"John Doe"}
                autoFocus
                error={(touched.name && formikErrors.name) || errors.name}
              />
              <InputField
                label={"Email"}
                value={values.email}
                placeholder={"johndoe@example.com"}
                onChange={handleChange("email")}
                error={(touched.email && formikErrors.email) || errors.email}
              />
              <InputField
                label={"Password"}
                value={values.password}
                onChange={handleChange("password")}
                passwordField
                error={
                  (touched.password && formikErrors.password) || errors.password
                }
              />
              <InputField
                label={"Confirm Password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                passwordField
                error={touched.confirmPassword && formikErrors.confirmPassword}
              />
              <Button
                type="submit"
                title="REGISTER"
                isLoading={isRegistering}
                loadingText=""
                error={errors.general}
              />
            </Form>
          );
        }}
      </Formik>
      <div
        style={{
          margin: "1rem 0",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StyledLink to={"/forgot-password"} onClick={closeModal}>
          <UnderLineButton>Forgot Password?</UnderLineButton>
        </StyledLink>
      </div>

      <Paragraph textAlign="center">
        Already have an account?
        <StyledLink to="/login" onClick={closeModal}>
          <HeadingPrimaryBold fontSize="1.6rem">
            &nbsp; Login Now
          </HeadingPrimaryBold>
        </StyledLink>
      </Paragraph>
    </>
  );
};

export default RegisterForm;
