import { Edit } from "@mui/icons-material";
import { credentialAxios } from "utils/api-calls";
import Button from "components/ui/Button/Button";
import CheckboxComp from "components/ui/CheckboxComp/CheckboxComp";
import Image from "components/ui/Image/Image";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Table from "components/ui/Table/Table";
import DataRenderer from "components/utility/DataRenderer";
import ListRenderer from "components/utility/ListRenderer";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  StyledAlignCenter,
} from "styles/common-styled-components";
import {
  API_ENDPOINTS,
  INCOMES,
  OCCUPATIONS,
  STATES_IN_INDIA,
  TAX_STATUSES,
} from "utils/constants";
import { DateStringtoDmy, mapServerErrorsToLocal } from "utils/functions";
import { NomineeCont } from "components/ui/RegistrationCapsule/RegistrationCapsule.styles";
import ErrorBoundary from "components/utility/ErrorBoundary";
import { useSelector } from "react-redux";

const EditBox = ({ label, comp, route }) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary fallback={<></>}>
      <div style={{ marginBottom: "30px" }}>
        <StyledAlignCenter style={{ marginBottom: "10px", gap: "12px" }}>
          <Edit
            onClick={() =>
              navigate(`/registration/${route}`, {
                state: { fromLocation: "/registration/confirm-details" },
              })
            }
            style={{
              cursor: "pointer",
              color: "var(--primary)",
              fontSize: "2rem",
            }}
          />
          <span
            style={{
              fontSize: "1.6rem",
              color: "var(--medium-grey)",
            }}
          >
            {label}
          </span>
        </StyledAlignCenter>
        {comp}
      </div>
    </ErrorBoundary>
  );
};

const ConfirmDetails = () => {
  const navigate = useNavigate();
  const { nextRegistrationScreen } = useSelector((state) => state.user);
  const initialErrorState = { general: "", checkbox: "" };

  const [errorState, setErrorState] = useState(initialErrorState);
  const [kycDetailsConfirmed, setKycDetailsConfirmed] = useState(false);

  //// API CALL

  const handleAddAddress = (data) => {
    return credentialAxios.post(API_ENDPOINTS.confirmKycData, {
      otp_required: true,
      otp: "",
      confirm: true,
    });
  };

  const onSuccess = () => {
    setErrorState("");
    navigate("/registration/confirm-kyc-otp");
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddAddress, {
    onSuccess,
    onError,
  });

  //// END API CALL
  return (
    <ErrorBoundary fallback={<>Provide all details to Edit and Confirm</>}>
      {nextRegistrationScreen &&
      !nextRegistrationScreen.includes("/confirm-details") ? (
        <>Provide all details to Edit and Confirm</>
      ) : (
        <RegistrationCapsule
          heading={"Confirm All your Details"}
          para={"Confirm all your KYC details, edit if necessary"}
        >
          <DataRenderer
            apiEndpoint={API_ENDPOINTS.getKycData}
            queryName={"kyc-info"}
            privateRequest
            renderData={(kycData) => {
              return (
                <NomineeCont>
                  <EditBox
                    route={"add-phone-number"}
                    label={"Phone Number"}
                    comp={<p>{kycData?.data?.phonenumber}</p>}
                  />
                  <EditBox
                    route={"verify-pan"}
                    label={"PAN"}
                    comp={<p>{kycData?.data?.pan}</p>}
                  />
                  <EditBox
                    route={"add-personal-details"}
                    label={"Personal Details"}
                    comp={
                      <Table
                        style={{ marginBottom: "2rem" }}
                        data={{
                          "Date of Birth": kycData.data.dob,
                          gender:
                            kycData?.data?.gender === "M" ? "Male" : "Female",
                        }}
                      />
                    }
                  />
                  <EditBox
                    route={"add-professional-details"}
                    label={"Professional Details"}
                    comp={
                      <Table
                        style={{ marginBottom: "2rem" }}
                        data={{
                          Occupation: OCCUPATIONS.filter(
                            (occupation) =>
                              occupation.OccupationCode ===
                              kycData?.data?.occ_code
                          )[0].Occupation,
                          "Income Slab": INCOMES.filter(
                            (income) =>
                              income.value === kycData?.data?.income_slab
                          )[0].label,
                          "Tax Status": TAX_STATUSES.filter(
                            (tax_status) =>
                              tax_status.taxCode === kycData?.data?.tax_status
                          )[0]?.taxStatus,
                        }}
                      />
                    }
                  />

                  <EditBox
                    route={"add-nominee"}
                    label={"Nominee Details"}
                    comp={
                      <ListRenderer
                        items={kycData.data.kycnominee}
                        renderItem={(nominee, index) => {
                          return (
                            <>
                              <p
                                style={{
                                  marginTop: "2rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                Nominee {index + 1}
                              </p>
                              <Table
                                style={{ marginBottom: "2rem" }}
                                data={{
                                  Name: nominee.name,
                                  "Relation with Nominee": nominee?.relation,
                                  "Applicable Percentage": nominee?.applicable,
                                  "Is Minor": nominee.minor ? "Yes" : "No",
                                  "Minor DOB": nominee?.minor_dob
                                    ? DateStringtoDmy(nominee?.minor_dob)
                                    : "-",
                                  "Minor Guardian": nominee?.minor_guardian
                                    ? nominee?.minor_guardian
                                    : "-",
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    }
                  />
                  <EditBox
                    label={"Address"}
                    route={"add-address"}
                    comp={
                      <Table
                        style={{ marginBottom: "2rem" }}
                        data={{
                          Address: kycData.data.address,
                          "Address Line 1": kycData?.data?.address1,
                          "Address Line 2": kycData?.data?.address2
                            ? kycData?.data?.address2
                            : "-",
                          City: kycData.data.city,
                          State: STATES_IN_INDIA.filter(
                            (state) => state.id === kycData?.data?.state
                          )[0].title,
                          Pincode: kycData?.data?.pincode,
                        }}
                      />
                    }
                  />

                  <EditBox
                    label={"Signature"}
                    route={"add-signature"}
                    comp={
                      <Image
                        alt={"_sign"}
                        src={`data:image;base64,${kycData.data.signature}`}
                        width="100%"
                        style={{
                          maxWidth: "40rem",
                          maxHeight: "30rem",
                          border: "1px solid #ccc",
                        }}
                      />
                    }
                  />
                  <CheckboxComp
                    checked={kycDetailsConfirmed}
                    label={"I confirm my details are accurate."}
                    onChange={() => {
                      setErrorState((prev) => {
                        return {
                          ...prev,
                          checkbox: "",
                        };
                      });
                      setKycDetailsConfirmed(!kycDetailsConfirmed);
                    }}
                  />
                  <ErrorMessage show={errorState.checkbox}>
                    {errorState.checkbox}
                  </ErrorMessage>
                  <p>A OTP will be sent before confirming details.</p>
                  <Button
                    isLoading={isLoading}
                    error={errorState.general}
                    title={"SEND OTP"}
                    onClick={() => {
                      if (!kycDetailsConfirmed) {
                        setErrorState((prev) => {
                          return {
                            ...prev,
                            checkbox: "Please confirm details to proceed",
                          };
                        });
                      } else {
                        mutate();
                      }
                    }}
                  />
                </NomineeCont>
              );
            }}
          />
        </RegistrationCapsule>
      )}
    </ErrorBoundary>
  );
};

export default ConfirmDetails;
