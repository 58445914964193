import React from "react";

import {
  ModalContainer,
  ModalLeft,
  ModalLogo,
  ModalRight,
} from "./LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  Paragraph,
  CloseButton,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";
import LoginForm from "components/forms/LoginForm";

const LoginModalContent = ({ closeModal, fromLocation = "/" }) => {
  return (
    <ModalContainer>
      <>
        <ModalLeft>
          <ModalLogo src={logo} />

          <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
            <HeadingPrimary color="#f7f8fa">
              Investing in{" "}
              <HeadingPrimaryBold color="#f7f8fa">
                Mutual Funds simplified
              </HeadingPrimaryBold>
            </HeadingPrimary>

            <HeadingPrimary color="#f7f8fa"></HeadingPrimary>
          </div>
        </ModalLeft>

        <ModalRight>
          <div style={{ width: "100%" }}>
            <HeadingCont>
              <HeadingPrimary>
                Login to <HeadingPrimaryBold>WiseBulls</HeadingPrimaryBold>
              </HeadingPrimary>

              <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
                <CloseButton onClick={closeModal} />
              </div>
            </HeadingCont>

            <Paragraph>
              Login/Signup to WiseBulls for a more personalised experience
            </Paragraph>

            <LoginForm closeModal={closeModal} fromLocation={fromLocation} />
          </div>
        </ModalRight>
      </>
    </ModalContainer>
  );
};

export default LoginModalContent;
