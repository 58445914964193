import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  DarkMode,
  LightMode,
  Logout,
  Search,
  KeyboardArrowDown,
} from "@mui/icons-material";

import Button from "components/common/Button/Button";
import SearchMutualFunds from "components/global/SearchMutualFunds/SearchMutualFunds";
import ModalPopup from "components/common/ModalPopup/ModalPopup";
import LoginModalContent from "components/common/LoginRegisterContent/LoginModalContent";

import RegisterModalContent from "components/common/LoginRegisterContent/RegisterModalContent";
import {
  ProfileButton,
  StyledAlignCenter,
  StyledLink,
} from "styles/common-styled-components";
import { ACCOUNT_NAVIGATION_OPTIONS } from "utils/constants";
import useOutsideClick from "hooks/useOutsideClick";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertContext } from "App";

import WiseBullsLogo2 from "assets/logos/logo.png";

import { changeTheme } from "redux/theme/theme-slice";

import {
  Logo,
  NavBarCont,
  NavLink,
  NavRight,
  AccountOption,
  AccountOptions,
  OptionsTop,
  MobileNavBarCont,
  AccountOptionCont,
} from "./NavBar.styles";
import LogoutFunctionality from "components/ui/LogoutFunctionality/LogoutFunctionality";
import Image from "components/ui/Image/Image";
import { useNavBar } from "./NavBarContext";

const NavBar = () => {
  const navigate = useNavigate();
  const { isNavBarVisible, showNavBar } = useNavBar();
  const { openModal, closeModal } = useContext(AlertContext);
  const { pathname } = useLocation();
  const { currentUser, userDetails } = useSelector((state) => state.user);
  const [showAccountOptions, setShowAccountOptions] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const accountOptionsRef = useRef(null);

  useOutsideClick(accountOptionsRef, () => {
    setShowAccountOptions(false);
  });

  useEffect(() => {
    showNavBar();
  }, [pathname]);

  return (
    <AnimatePresence>
      {isNavBarVisible ? (
        <NavBarCont
          as={motion.div}
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          mobileNav={pathname.includes("registration") ? true : false}
        >
          <StyledLink to="/">
            <StyledAlignCenter>
              <Image
                src={WiseBullsLogo2}
                alt="WiseBulls logo"
                size={"4.8rem"}
              />
              <p style={{ fontSize: "1.6rem", fontWeight: "500" }}>WiseBulls</p>
            </StyledAlignCenter>
          </StyledLink>
          {pathname.includes("payment-success") ||
          pathname.includes("payment-failed") ||
          pathname === "/login" ||
          pathname === "/register" ||
          pathname === "/upi-transaction-timer" ? (
            <></>
          ) : (
            <NavRight>
              <>
                <SearchMutualFunds />
                <StyledLink to="/" style={{ textDecoration: "none" }}>
                  <NavLink active={pathname === "/"}>Home</NavLink>
                </StyledLink>

                {currentUser ? (
                  <>
                    <StyledLink
                      to="/investments"
                      style={{ textDecoration: "none" }}
                    >
                      <NavLink active={pathname === "/investments"}>
                        Investments
                      </NavLink>
                    </StyledLink>
                    <StyledAlignCenter
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowAccountOptions(true)}
                    >
                      <ProfileButton aria-label="Account Options">
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                        >
                          {userDetails?.email[0].toUpperCase()}
                        </motion.span>
                      </ProfileButton>
                      <KeyboardArrowDown style={{ fontSize: "2rem" }} />
                    </StyledAlignCenter>
                  </>
                ) : (
                  <>
                    <Button
                      name="Login"
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal({
                          comp: <LoginModalContent closeModal={closeModal} />,
                        });
                      }}
                    />
                    <Button
                      name="Register"
                      onClick={() => {
                        openModal({
                          comp: (
                            <RegisterModalContent closeModal={closeModal} />
                          ),
                        });
                      }}
                      variant="outline"
                    />
                  </>
                )}
              </>
            </NavRight>
          )}

          {/* MENU */}
          {showAccountOptions && (
            <AccountOptions
              initial={{ y: 15 }}
              animate={{ y: 0, transition: { duration: 0.3, type: "linear" } }}
              as={motion.div}
              show={showAccountOptions}
              ref={accountOptionsRef}
            >
              <AccountOptionsComp
                userDetails={userDetails}
                setShowAccountOptions={setShowAccountOptions}
              />
            </AccountOptions>
          )}

          {/* MODALS */}
          <ModalPopup
            show={showLoginModal}
            onClose={() => setShowLoginModal(false)}
            style={{ maxHeight: "75vh" }}
          >
            <LoginModalContent closeModal={() => setShowLoginModal(false)} />
          </ModalPopup>
        </NavBarCont>
      ) : null}

      {/* MOBILE NAVBAR */}
      {pathname === "/" ? (
        <MobileNavBarCont
          onClick={() => {
            navigate("/search");
          }}
        >
          <Logo
            style={{ width: "4rem" }}
            src={WiseBullsLogo2}
            alt="WiseBulls logo"
          />

          <StyledAlignCenter style={{ color: "var(--mediumGrey)" }}>
            <Search style={{ fontSize: "1.8rem" }} />
            <p>Search Mutual Funds</p>
          </StyledAlignCenter>

          {currentUser ? (
            <>
              <ProfileButton
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/account");
                }}
              >
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  {userDetails.email[0].toUpperCase()}
                </motion.span>
              </ProfileButton>
            </>
          ) : (
            <>
              <Button
                style={{
                  borderRadius: "100px",
                }}
                name="Login"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/login");
                }}
              />
            </>
          )}
        </MobileNavBarCont>
      ) : null}

      {/* <FullWidthTabs /> */}
      {/* MOBILE TABS */}
    </AnimatePresence>
  );
};

export default NavBar;

export const AccountOptionsComp = ({ setShowAccountOptions, userDetails }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.mode);

  return (
    <>
      <OptionsTop>
        <ProfileButton>{userDetails.email[0].toUpperCase()}</ProfileButton>

        <div>
          <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
            {userDetails.email}
          </p>
        </div>
      </OptionsTop>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {ACCOUNT_NAVIGATION_OPTIONS.map((option, index) => {
          return (
            <StyledLink to={option.path} key={index}>
              <AccountOption
                key={index}
                onClick={() => setShowAccountOptions(false)}
              >
                <AccountOptionCont>
                  <>{option.icon}</>
                </AccountOptionCont>

                <div>
                  <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                    {option.name}
                  </p>
                  <p style={{ fontSize: "1.3rem" }}>{option.caption}</p>
                </div>
              </AccountOption>
            </StyledLink>
          );
        })}
        {/* <AccountOption
          onClick={() => {
            if (theme === "light") {
              dispatch(changeTheme("dark"));
            } else {
              dispatch(changeTheme("light"));
            }
          }}
        >
          <AccountOptionCont>
            {theme === "light" ? <DarkMode /> : <LightMode />}
          </AccountOptionCont>

          <div>
            <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {theme === "light" ? "Dark" : "Light"} Mode
            </p>
            <p style={{ fontSize: "1.3rem" }}>
              Change Theme to {theme === "light" ? "Dark" : "Light"}
            </p>
          </div>
        </AccountOption> */}
        <LogoutFunctionality>
          <AccountOption>
            <AccountOptionCont>
              <Logout />
            </AccountOptionCont>

            <div>
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Logout</p>
              <p style={{ fontSize: "1.3rem" }}>Logout from your account</p>
            </div>
          </AccountOption>
        </LogoutFunctionality>
      </div>
    </>
  );
};
