import React from "react";

import {
  Bottom,
  Container,
  MainCont,
  ModalLogo,
  Top,
  WidthController,
} from "components/common/LoginRegisterContent/LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  Paragraph,
  Paragraph2,
  StyledLink,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";
import useDocumentTitle from "hooks/useDocumentTitle";
import LoginForm from "components/forms/LoginForm";

const Login = ({ closeModal }) => {
  useDocumentTitle("Login - WiseBulls");

  return (
    <MainCont>
      <Container>
        <>
          <Top loginRegisterPage={true}>
            <StyledLink to={"/"}>
              <ModalLogo src={logo} />
            </StyledLink>

            <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
              <HeadingPrimary color="#f7f8fa">
                Investing in{" "}
                <HeadingPrimaryBold color="#f7f8fa">
                  Mutual Funds simplified
                </HeadingPrimaryBold>
              </HeadingPrimary>

              <HeadingPrimary color="#f7f8fa"></HeadingPrimary>
            </div>
          </Top>

          <Bottom>
            <WidthController>
              <HeadingCont>
                <HeadingPrimary>
                  Login to <HeadingPrimaryBold>WiseBulls</HeadingPrimaryBold>
                </HeadingPrimary>
              </HeadingCont>

              <Paragraph>
                Login/Signup to WiseBulls for a more personalised experience
              </Paragraph>

              <LoginForm />
              <div style={{ marginBottom: "2rem", textAlign: "center" }}>
                <Paragraph
                  style={{ margin: 0, marginBottom: "2rem" }}
                  textAlign="center"
                >
                  Or
                </Paragraph>

                {/* STATIC CONTENT */}

                <StyledLink
                  style={{
                    display: "inline-block",
                    marginBottom: "2rem",
                  }}
                  className="Link"
                  to="/"
                >
                  <Paragraph2
                    style={{
                      color: "var(--themeColor)",
                      fontWeight: "600",
                      margin: 0,
                    }}
                  >
                    Skip Login and Explore
                  </Paragraph2>
                </StyledLink>
              </div>
            </WidthController>
          </Bottom>
        </>
      </Container>
    </MainCont>
  );
};

export default Login;
