import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { CurrencyField } from "components/common/InputField/InputField";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";
import { CloseButton, Form2 } from "styles/common-styled-components";
import Button from "components/ui/Button/Button";
import CheckboxComp from "components/ui/CheckboxComp/CheckboxComp";
import Select from "components/ui/Select/Select";
import { AlertContext } from "App";
import { useNavigate } from "react-router-dom";

const RedeemForm = ({ state, mfData }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDetails);
  const { openModal, closeModal } = useContext(AlertContext);

  const bank_options = user?.bankdetail?.map((curr) => {
    return {
      label: `${curr.bank_name} (${"X".repeat(
        curr.account_number.length - 4
      )}${curr.account_number.substr(curr.account_number.length - 4)})`,
      value: curr.account_number,
    };
  });

  let defaultBank = user.bankdetail.filter((curr) => curr.default);

  if (defaultBank.length === 0) {
    defaultBank = user.bankdetail;
  }

  const redeemableAmount = (state.nav * state.units).toFixed(2);

  const minimum_redemption_amount = mfData.minimum_redemption_amount;

  Yup.addMethod(Yup.number, "checkRedeemAmount", function (message) {
    return this.test("check-redeem-amount", message, function (value) {
      if (Number(value) > redeemableAmount) {
        this.createError({
          path: this.path,
          message,
        });
      } else {
        return true;
      }
    });
  });

  Yup.addMethod(Yup.number, "checkMinRedeemAmount", function (message) {
    return this.test("check-min-redeem-amount", message, function (value) {
      if (Number(value) < minimum_redemption_amount) {
        this.createError({
          path: this.path,
          message,
        });
      } else {
        return true;
      }
    });
  });

  const initialValues = {
    amount: "",
    all_redeem: false,
    account_number: defaultBank[0].account_number,
  };

  const initialErrorState = {
    amount: "",
    all_redeem: "",
    general: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .checkRedeemAmount("Redeem amount cannot be more than Redeemable amount")
      .checkMinRedeemAmount(
        `Minimum redemption amount is ₹${minimum_redemption_amount}`
      ),
    all_redeem: Yup.bool(),
  });

  const [errorsState, setErrorsState] = useState(initialErrorState);
  const [disabledAmountField, setDisabledAmountField] = useState(false);

  const handleRedeemAmount = (data) => {
    const payload = { ...data, folio_number: state.folio_number };
    return credentialAxios.post(API_ENDPOINTS.redeem, payload);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState);
    setErrorsState(newErrors);
  };

  const onSuccess = () => {
    setErrorsState(initialErrorState);
    openModal({
      comp: (
        <div closeModal={closeModal} style={{ padding: "2rem" }}>
          <div style={{ marginBottom: "4rem" }}>
            <CloseButton onClick={() => closeModal()} />
          </div>
          <h3 style={{ marginBottom: "1rem", textAlign: "center" }}>
            Success!
          </h3>
          <p style={{ marginBottom: "1rem" }}>
            Redemption request is successful, to see the status, go to orders.{" "}
          </p>
          <Button
            title={"ORDERS"}
            onClick={() => {
              closeModal();
              navigate("/orders");
            }}
          />
        </div>
      ),
      style: { minWidth: "30rem" },
    });
  };

  const { mutate: redeem, isLoading: isRedeeming } = useMutation(
    handleRedeemAmount,
    {
      onError,
      onSuccess,
    }
  );

  return (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          margin: "1rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "1.4rem" }}>Total Redeemable (approx.)</p>
        <p
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          ₹ {redeemableAmount}
        </p>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={redeem}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form2
              style={{ padding: 0, margin: "3rem 0" }}
              onSubmit={handleSubmit}
            >
              <Select
                options={bank_options}
                label={"Select Bank account"}
                value={values.account_number}
                onChange={handleChange("account_number")}
              />
              <CurrencyField
                label={"Enter Amount"}
                type="currency"
                placeholder="₹"
                onValueChange={(value) => {
                  setFieldValue("amount", value);
                }}
                defaultValue={100}
                value={values.amount}
                disabled={disabledAmountField}
                error={(touched.amount && errors.amount) || errorsState.amount}
              />
              <CheckboxComp
                label={"Redeem All"}
                checked={values.all_redeem}
                onChange={() => {
                  const newValue = !values.all_redeem;
                  setFieldValue("all_redeem", newValue);
                  if (newValue) {
                    setDisabledAmountField(true);
                    setFieldValue("amount", redeemableAmount);
                  } else {
                    setDisabledAmountField(false);
                  }
                }}
              />
              <Button
                type="submit"
                title="REDEEM"
                isLoading={isRedeeming}
                loadingText=""
                error={errorsState.general}
              />
            </Form2>
          );
        }}
      </Formik>
    </div>
  );
};

export default RedeemForm;
