import React from "react";
import MobileNav from "components/common/MobileNav/MobileNav";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button/Button";
import {
  BottomMessageCont,
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";
import DataTable from "components/common/DataTable";
import { getDateSuffix } from "utils/functions";
import CopyText from "components/common/CopyText/CopyText";
import DataRenderer from "components/utility/DataRenderer";

const OrderDetails = () => {
  const { orderNumber } = useParams();
  const navigate = useNavigate();

  const handleShowPaymentButton = (status) => {
    switch (status) {
      case "Requested internally":
      case "Cancelled/Failed":
      case "Order successfully placed at BSE":
        return "Seems like your Order is not completed yet, click below button to continue";
      case "PAYMENT NOT INITIATED FOR GIVEN ORDER":
        return "Continue your Order by clicking below button.";
      default:
        return null;
    }
  };

  const sip_start_date_to_day = (sip_start_date) => {
    const day = sip_start_date.split("-")[2];
    return `${day}${getDateSuffix(day)}`;
  };

  const onContinueOrder = (data) => {
    navigate("/payment", {
      state: {
        comingFromOrders: true,
        order_number: orderNumber,
        amount: data.amount,
        investmentType: data.orderType,
        monthlySipDate: !data.sip_start_date
          ? null
          : sip_start_date_to_day(data.sip_start_date),
        name: data.scheme_name,
        scheme_id: data.id,
      },
    });
  };

  return (
    <StyledCentering>
      <div style={{ position: "relative", maxWidth: "60rem" }}>
        <MobileNav headerTitle={"Order Details"} />
        <DataRenderer
          apiEndpoint={`/api/orders/${orderNumber}/`}
          privateRequest
          queryName={`orders - ${orderNumber}`}
          renderData={(state) => {
            const scheme_name = state?.data.scheme_name || "N/A";
            const order_type =
              state?.data.order_type === "1" ? "Lumpsum" : "SIP";
            const transaction_type = state?.data.transaction_type || "N/A";
            const amount = state?.data.amount || "N/A";
            const created = new Date(state?.data.created).toString() || "N/A";
            const order_number = orderNumber || "N/A";
            return (
              <>
                <p
                  style={{
                    fontWeight: "800",
                    textAlign: "center",
                    fontSize: "1.4rem",
                    marginBottom: "1.4rem",
                  }}
                >
                  {scheme_name}
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    textAlign: "center",
                    fontSize: "1.4rem",
                    marginBottom: "1.4rem",
                  }}
                >
                  {state?.data.status}
                </p>
                <DataTable
                  data={{
                    ...(transaction_type !== "Redemption" && {
                      "Investment Type": order_type,
                    }),
                    "Transaction Type": transaction_type,
                    Amount: amount,
                    "Placed At": new Date(created).toString(),
                    "Reference Number": (
                      <StyledAlignCenter>
                        <span>{order_number}</span>
                        <CopyText copyText={order_number} />
                      </StyledAlignCenter>
                    ),
                  }}
                />
                {handleShowPaymentButton(state?.data.status) &&
                orderNumber &&
                transaction_type !== "Redemption" ? (
                  <BottomMessageCont>
                    <div style={{}}>
                      <p style={{ marginBottom: "1.2rem" }}>
                        {handleShowPaymentButton(state.data.status)}
                      </p>
                      {
                        <Button
                          name="CONTINUE ORDER"
                          onClick={() =>
                            onContinueOrder({
                              scheme_name,
                              orderType:
                                state?.data.order_type === "1"
                                  ? "lumpsum"
                                  : "sip",
                              transaction_type,
                              amount,
                              sip_start_date: state.data.sip_start_date,
                              scheme_id: state.data.id,
                            })
                          }
                        />
                      }
                    </div>
                  </BottomMessageCont>
                ) : null}
              </>
            );
          }}
        />
      </div>
    </StyledCentering>
  );
};

export default OrderDetails;
