import {
  AutoGraph,
  Savings,
  Apartment,
  Home,
  Store,
  Inventory,
  TrendingUp,
  TrendingDown,
  EventRepeat,
  Payments,
  Recommend,
} from "@mui/icons-material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { AccountBalance, Help, Assignment } from "@mui/icons-material";

import adithyaLogo from "assets/logos/aditya.png";
import hdfcLogo from "assets/logos/hdfc.png";
import iciciLogo from "assets/logos/icici.png";
import idfcLogo from "assets/logos/idfc.png";

import fundSize from "assets/icons/fund-size.png";
import nav from "assets/icons/nav.png";
import dayChange from "assets/icons/day-change.png";
import minSip from "assets/icons/min-sip.png";
import minLumpsum from "assets/icons/lumpsum.png";
import { NumberLink } from "pages/Help/Help.styles";

export const MF_INFO_MAP = {
  Riskometer: null,
  "Fund Size": fundSize,
  NAV: nav,
  "Day Change": dayChange,
  "Min SIP": minSip,
  "Min Lumpsum": minLumpsum,
};

export const RISK_VALUES = {
  Low: 100,
  "Moderately low": 300,
  Moderate: 500,
  "Moderately High": 700,
  "Very High": 900,
};

export const COMP_DIMENSIONS = {
  HEIGHTS: {
    graph: 300,
  },
};

export const REGULAR_EXPRESSIONS = {
  phone_number:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  vpa_id: /[a-z0-9]*@[a-z]*/,
};

export const GENERIC_SERVER_ERROR = "Something went wrong! Please try again.";

export const ANIMATION_STYLES = {
  scale: {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      scale: 0,
    },
  },
  slideFromBottom: {
    initial: {
      y: 300,
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      y: 300,
    },
  },
  opacity: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  },

  slide: {
    initial: { x: window.innerWidth },
    animate: {
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      x: -window.innerWidth,
    },
  },
};

const navBarHeight = "10rem";

export const STYLE_VALUES = {
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  lightBoxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px",
  navBarHeight: navBarHeight,
  remainingScreenHeight: `calc(100vh - ${navBarHeight})`,
  basicCompWidth: "50rem",
};

export const API_ENDPOINTS = {
  getGraphData: `/get-historical-nav/`,
  getAllMutualFunds: `/api/scheme/`,
  getInvestments: `/api/investments/`,
  getOrdersDetails: `/api/orders/`,
  getBanks: `/api/list-banks/`,
  getBranches: (id) => `/api/list-branch/${id}`,
  getBankByIfsc: (bankIfsc) => `/api/get-branch/${bankIfsc}/`,
  getFundHouses: `/api/fundhouse/`,
  getFundCategories: `/api/cetegory/`,
  getMutualFunds: `/api/scheme/`,
  filterMutualFunds: `/api/get-schemes/`,
  getMFDetails: (id) => `/api/scheme/${id}/`,
  autocompleteMutualFund: `/get_schemes_for_auto_complete/`,
  createOneTimeTransaction: `/api/onetime-transaction/`,
  createSipTransaction: `/api/sip-transaction/`,
  checkTransactionStatus: `/api/payment-status/`,
  payFromOrders: `/api/pay/`,
  sendResetPasswordLink: `/api/user/password_reset/`,
  confirmResetToken: `/api/user/password_reset/confirm/`,
  loginTokenRequest: `/api/user/token/`,
  getUserInfo: `/api/user/user-info/`,
  getKycData: `/api/user/check-kyc-data/`,
  createUser: `/api/user/create/`,
  addPhoneNumber: `/api/user/add-phone-number/`,
  addName: `/api/user/add-name/`,
  addPersonalDetails: `/api/user/personal-detail/`,
  addProfessionalDetails: `/api/user/professional-detail/`,
  addNominee: `/api/user/nominee-detail/`,
  addAddress: `/api/user/address-detail/`,
  addMandate: `/api/add-mandate/`,
  getMandateLink: `/api/get-mandate-link/`,
  postMandateStatus: `/api/post-mandate-status/`,
  addBank: `/api/user/add-bank/`,
  changeDefaultBank: `/api/user/change-default-bank/`,
  verifyOtp: `/api/user/verify-otp/`,
  verifyPan: `/api/user/add-pan/`,
  uploadSignature: `/api/user/upload-signature/`,
  redeem: `/api/redeem/`,
  cancelSip: `/api/cancel-sip/`,
  verifyPin: `/api/user/verify-pin/`,
  confirmKycData: "/api/user/confirm-data/",
  schemeDetails: (id) => `/api/schemedetails/${id}/`,
  topPerformingFunds: `/api/research/top-performing-funds/`,
  consistentPerformers: `/api/research/consistent-performers/`,
  topSipFunds: `/api/research/top-sip-funds/`,
  topLumpsumFunnds: `/api/research/top-lumpsum-funds/`,
};

export const REGISTRATION_LENGTH = 10;

export const REG_LINKS = {
  phoneNumberVerified: "add-phone-number",
  panVerified: "verify-pan",
  personalDetailsAdded: "add-personal-details",
  professionalDetailsAdded: "add-professional-details",
  nomineeAdded: "add-nominee",
  addressAdded: "add-address",
  bankAdded: "select-bank",
  signatureAdded: "add-signature",
  kycDetailsConfirmed: "confirm-details",
};

export const PROGRESS_MAPPER = {
  AddPhoneNumber: 1,
  VerifyPAN: 2,
  AddPersonalDetails: 3,
  AddProfessionalDetails: 4,
  SelectBank: 5,
  AddNominee: 6,
  AddAddress: 7,
  AddSignatue: 8,
  ConfirmDetails: 9,
};

export const HARD_CODED_TOP_MUTUALFUNDS = [
  {
    title: "HDFC TAX SAVER - GROWTH OPTION",
    perc: "21.2",
    returns: "3",
    logo: hdfcLogo,
    id: "1901",
  },
  {
    title: "ICICI PRUDENTIAL INDIA EQUITY FOF GROWTH",
    perc: "33.05",
    returns: "3",
    logo: iciciLogo,
    id: "5414",
  },
  {
    title: "IDFC BALANCED ADVANTAGE FUND REGULAR PLAN - GROWTH",
    perc: "22",
    returns: "3",
    logo: idfcLogo,
    id: "13667",
  },
  {
    title: "ADITYA BIRLA SUN LIFE FLEXI CAP FUND - GROWTH",
    perc: "34.2",
    returns: "3",
    logo: adithyaLogo,
    id: "46",
  },
];

const iconStyle = {
  fontSize: "3.4rem",
  color: "var(--themeColor)",
};

export const COLLECTION_OPTIONS = [
  {
    name: "High Returns",
    icon: <AutoGraph style={iconStyle} />,
    path: "/collections/high-returns",
  },
  {
    name: "Tax Saving",
    icon: <Savings style={iconStyle} />,
    path: "/collections/tax-saving",
  },
  {
    name: "Large Cap",
    icon: <Apartment style={iconStyle} />,
    path: "/collections/large-cap",
  },
  {
    name: "Mid Cap",
    icon: <Home style={iconStyle} />,
    path: "/collections/mid-cap",
  },

  {
    name: "Small Cap",
    icon: <Store style={iconStyle} />,
    path: "/collections/small-cap",
  },
  {
    name: "Better than FD",
    icon: <Inventory style={iconStyle} />,
    path: "/collections/better-than-fd",
  },
];

export const ACCOUNT_NAVIGATION_OPTIONS = [
  {
    name: "Investments",
    caption: "Your Investment details",
    path: "/investments",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
  },
  {
    name: "Bank Details",
    caption: "Bank and autopay mandates",
    path: "/bank-details",
    icon: <AccountBalance />,
  },
  {
    name: "All Orders",
    caption: "Track orders, order details",
    path: "/orders",
    icon: <Assignment />,
  },

  {
    name: "Help and Support",
    caption: "FAQS, Contact support",
    path: "/help",
    icon: <Help />,
  },
];

export const OCCUPATIONS = [
  {
    Occupation: "Business",
    OccupationCode: "01",
    Type: "Business",
  },
  {
    Occupation: "Service",
    OccupationCode: "02",
    Type: "Service",
  },
  {
    Occupation: "Professional",
    OccupationCode: "03",
    Type: "Service",
  },
  {
    Occupation: "Agriculturist",
    OccupationCode: "04",
    Type: "Service",
  },
  {
    Occupation: "Retired",
    OccupationCode: "05",
    Type: "Others",
  },
  {
    Occupation: "Housewife",
    OccupationCode: "06",
    Type: "Others",
  },
  {
    Occupation: "Student",
    OccupationCode: "07",
    Type: "Others",
  },
  {
    Occupation: "Others",
    OccupationCode: "08",
    Type: "Others",
  },
  {
    Occupation: "Doctor",
    OccupationCode: "09",
    Type: "Service",
  },
  {
    Occupation: "Private Sector Service",
    OccupationCode: "41",
    Type: "Service",
  },
  {
    Occupation: "Public Sector Service",
    OccupationCode: "42",
    Type: "Service",
  },
  {
    Occupation: "Forex Dealer",
    OccupationCode: "43",
    Type: "Business",
  },
  {
    Occupation: "Government Service",
    OccupationCode: "44",
    Type: "Service",
  },
  {
    Occupation: "Unknown / Not Applicable",
    OccupationCode: "99",
    Type: "Others",
  },
];

export const INCOMES = [
  { label: "Below 1 Lakh", value: "31" },
  { label: "> 1 <=5 Lacs", value: "32" },
  { label: ">5 <=10 Lacs", value: "33" },
  { label: ">10 <= 25 Lacs", value: "34" },
  { label: "> 25 Lacs < = 1 Crore", value: "35" },
  { label: "Above 1 Crore", value: "36" },
];

export const STATES_IN_INDIA = [
  { id: "AN", title: "Andaman & Nicobar" },
  { id: "AR", title: "Arunachal Pradesh" },
  { id: "AP", title: "Andhra Pradesh" },
  { id: "AS", title: "Assam" },
  { id: "BH", title: "Bihar" },
  { id: "CH", title: "Chandigarh" },
  { id: "CG", title: "Chhattisgarh" },
  { id: "DL", title: "Delhi" },
  { id: "GO", title: "GOA" },
  { id: "GU", title: "Gujarat" },
  { id: "HA", title: "Haryana" },
  { id: "HP", title: "Himachal Pradesh" },
  { id: "JM", title: "Jammu & Kashmir" },
  { id: "JK", title: "Jharkhand" },
  { id: "KA", title: "Karnataka" },
  { id: "KE", title: "Kerala" },
  { id: "MP", title: "Madhya Pradesh" },
  { id: "MA", title: "Maharashtra" },
  { id: "MN", title: "Manipur" },
  { id: "ME", title: "Meghalaya" },
  { id: "MI", title: "Mizoram" },
  { id: "NA", title: "Nagaland" },
  { id: "ND", title: "New Delhi" },
  { id: "OR", title: "Orissa" },
  { id: "PO", title: "Pondicherry" },
  { id: "PU", title: "Punjab" },
  { id: "RA", title: "Rajasthan" },
  { id: "SI", title: "Sikkim" },
  { id: "TG", title: "Telengana" },
  { id: "TN", title: "Tamil Nadu" },
  { id: "TR", title: "Tripura" },
  { id: "UP", title: "Uttar Pradesh" },
  { id: "UC", title: "Uttaranchal" },
  { id: "WB", title: "West Bengal" },
  { id: "DN", title: "Dadra and Nagar Haveli" },
  { id: "DD", title: "Daman and Diu" },
];

export const TAX_STATUSES = [
  { taxCode: "01", taxStatus: "Individual", catgory: "R - Retail" },
  { taxCode: "02", taxStatus: "On Behalf Of Minor", catgory: "R - Retail" },
  { taxCode: "03", taxStatus: "HUF", catgory: "R - Retail" },
  { taxCode: "04", taxStatus: "Company", catgory: "C - Corporate" },
  { taxCode: "05", taxStatus: "AOP/BOI", catgory: "C - Corporate" },
  { taxCode: "06", taxStatus: "Partnership Firm", catgory: "C - Corporate" },
  { taxCode: "07", taxStatus: "Body Corporate", catgory: "C - Corporate" },
  { taxCode: "08", taxStatus: "Trust", catgory: "C - Corporate" },

  { taxCode: "09", taxStatus: "Society", category: "C - Corporate" },
  { taxCode: "10", taxStatus: "Others", category: "C - Corporate" },
  { taxCode: "11", taxStatus: "NRI-Others", category: "R - Retail" },
  {
    taxCode: "12",
    taxStatus: "Banks / Financial Institutions",
    category: "C - Corporate",
  },
  {
    taxCode: "13",
    taxStatus: "Sole Proprietorship",
    category: "C - Corporate",
  },
  { taxCode: "14", taxStatus: "Banks", category: "C - Corporate" },
  {
    taxCode: "15",
    taxStatus: "Association of Persons",
    category: "C - Corporate",
  },
  {
    taxCode: "21",
    taxStatus: "NRI - NRE (Repatriation)",
    category: "R - Retail",
  },
  {
    taxCode: "22",
    taxStatus: "Overseas Corporate Body",
    category: "C - Corporate",
  },
  {
    taxCode: "23",
    taxStatus: "Foreign Institutional Investor",
    category: "C - Corporate",
  },
  {
    taxCode: "24",
    taxStatus: "NRI - NRO [Non Repatriation]",
    category: "R - Retail",
  },
  {
    taxCode: "25",
    taxStatus: "Overseas Corporate Body-Others",
    category: "C - Corporate",
  },
  { taxCode: "26", taxStatus: "NRI - Minor (NRE)", category: "R - Retail" },

  { taxCode: "27", taxStatus: "NRI-HUF(NRO)", category: "R - Retail" },
  { taxCode: "28", taxStatus: "NRI - Minor (NRO)", category: "R - Retail" },
  { taxCode: "29", taxStatus: "NRI-HUF(NRE)", category: "R - Retail" },
  {
    taxCode: "31",
    taxStatus: "Providend Fund / EPF / PF Trust",
    category: "C - Corporate",
  },
  { taxCode: "32", taxStatus: "Superannuation", category: "C - Corporate" },
  { taxCode: "33", taxStatus: "Gratuity Fund", category: "C - Corporate" },
  {
    taxCode: "36",
    taxStatus: "Mutual Funds FOF Schemes",
    category: "C - Corporate",
  },
  { taxCode: "37", taxStatus: "NPS Trust", category: "C - Corporate" },
  {
    taxCode: "38",
    taxStatus: "Global Development Network",
    category: "C - Corporate",
  },
  { taxCode: "39", taxStatus: "FCRA", category: "C - Corporate" },
  {
    taxCode: "41",
    taxStatus: "Qualified Foreign Investor-Individual",
    category: "R - Retail",
  },
  {
    taxCode: "42",
    taxStatus: "Qualified Foreign Investor-Minors",
    category: "R - Retail",
  },
  {
    taxCode: "43",
    taxStatus: "Qualified Foreign Investor-Corporate",
    category: "C - Corporate",
  },
  {
    taxCode: "44",
    taxStatus: "Qualified Foreign Investor-Pension Fund",
    category: "C - Corporate",
  },
  {
    taxCode: "45",
    taxStatus: "Qualified Foreign Investor-Hedge Funds",
    category: "C - Corporate",
  },
  {
    taxCode: "46",
    taxStatus: "Qualified Foreign Investor-Mutual Funds",
    category: "C - Corporate",
  },
  {
    taxCode: "47",
    taxStatus: "Limited Liability Partnership",
    category: "C - Corporate",
  },
  {
    taxCode: "48",
    taxStatus: "Non-Profit organization [NPO]",
    category: "C - Corporate",
  },
  {
    taxCode: "51",
    taxStatus: "Public Limited Company",
    category: "C - Corporate",
  },
  {
    taxCode: "52",
    taxStatus: "Private Limited Company",
    category: "C - Corporate",
  },
  { taxCode: "53", taxStatus: "Unlisted Company", category: "C - Corporate" },
  { taxCode: "54", taxStatus: "Mutual Funds", category: "C - Corporate" },
  { taxCode: "55", taxStatus: "FPI - Category I", category: "C - Corporate" },
  { taxCode: "56", taxStatus: "FPI - Category II", category: "C - Corporate" },
  { taxCode: "57", taxStatus: "FPI - Category III", category: "C - Corporate" },
  {
    taxCode: "58",
    taxStatus: "Financial Institutions",
    category: "C - Corporate",
  },
  {
    taxCode: "59",
    taxStatus: "Body of Individuals",
    category: "C - Corporate",
  },
  { taxCode: "60", taxStatus: "Insurance Company", category: "C - Corporate" },
  { taxCode: "61", taxStatus: "OCI - Repatriation", category: "R - Retail" },
  {
    taxCode: "62",
    taxStatus: "OCI - Non Repatriation",
    category: "R - Retail",
  },
  {
    taxCode: "70",
    taxStatus: "Person of Indian Origin [PIO]",
    category: "R - Retail",
  },
  { taxCode: "72", taxStatus: "Government Body", category: "C - Corporate" },
  {
    taxCode: "73",
    taxStatus: "Defence Establishment",
    category: "C - Corporate",
  },
  {
    taxCode: "74",
    taxStatus: "Non-Government Organisation [NGO]",
    category: "C - Corporate",
  },
  {
    taxCode: "75",
    taxStatus: "Artificial Juridical Person",
    category: "C - Corporate",
  },
  { taxCode: "76", taxStatus: "Trust - Liquidator", category: "C - Corporate" },
];

export const REGISTRATION_STEPS = [
  {
    label: "Add Phone Number",
    description: `In order to verify your KYC, phone number is required. We will send you an OTP to the mobile number you will provide.`,
    url: ["/registration/add-phone-number", "/registration/otp-verification"],
  },
  {
    label: "Verify PAN",
    description: `SEBI mandates the use of PAN for Mutual Fund investments.`,
    url: ["/registration/verify-pan"],
  },
  {
    label: "Add Personal Details",
    description: `Knowing the gender and age of an investor can assist financial institutions in personalizing their services.`,
    url: ["/registration/add-personal-details"],
  },
  {
    label: "Add Professional Details",
    description: `To ensure regulatory compliance, offer personalized financial advice, and mitigate risks associated with financial transactions.`,
    url: ["/registration/add-professional-details"],
  },
  {
    label: "Add Bank",
    description: `Bank details are necessary for processing financial transactions related to mutual fund investments`,
    url: [
      "/registration/select-bank",
      "/registration/select-branch",
      "/registration/add-bank",
    ],
  },
  {
    label: "Add Nominee",
    description: `Nomination allows the investor to specify a person or entity to receive the investment proceeds in the event of their death.`,
    url: ["/registration/add-nominee"],
  },
  {
    label: "Add Address",
    description: `Providing an address is essential for communication, regulatory compliance, and identity verification in financial processes`,
    url: ["/registration/add-address"],
  },
  {
    label: "Add Signature",
    description: `The signature serves as a legal representation of the individual's consent and authorization for financial transactions.`,
    url: ["/registration/add-signature"],
  },
  {
    label: "Confirm Details",
    description: `You can check, edit and confirm the Details you have submitted.`,
    url: ["/registration/confirm-details", "/registration/confirm-kyc-otp"],
  },
];

export const RELATION_OPTIONS = [
  {
    label: "Father",
    value: "Father",
  },
  {
    label: "Mother",
    value: "Mother",
  },
  {
    label: "Wife",
    value: "Wife",
  },
  {
    label: "Husband",
    value: "Husband",
  },
  {
    label: "Son",
    value: "Son",
  },
  {
    label: "Daughter",
    value: "Daughter",
  },
  {
    label: "Father-in-law",
    value: "Father in law",
  },
  {
    label: "Mother-in-law",
    value: "Mother in law",
  },
  {
    label: "Son-in-law",
    value: "Son in law",
  },
  {
    label: "Daughter-in-law",
    value: "Daughter in law",
  },
  {
    label: "Aunt",
    value: "Aunt",
  },
  {
    label: "Uncle",
    value: "Uncle",
  },
  {
    label: "Niece",
    value: "Niece",
  },
  {
    label: "Nephew",
    value: "Nephew",
  },
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Sister",
    value: "Sister",
  },
  {
    label: "Grand Father",
    value: "Grand Father",
  },
  {
    label: "Grand Mother",
    value: "Grand Mother",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const GENERIC_ERROR_RES_MSG = "Something went wrong! Please try again.";

export const COLLECTIONS = [
  {
    label: "Top Performing Funds",
    description: "Funds that have performed the best over the past year.",
    icon: <TrendingUp style={{ fontSize: "2.4rem" }} />,
    path: "/collections/top-performing-funds",
  },
  // {
  //   label: "Top SIP Funds",
  //   description: "Best funds for Systematic Investment Plans.",
  //   icon: <EventRepeat style={{ fontSize: "2.4rem" }} />,
  //   path: "/collections/top-sip-funds",
  // },
  // {
  //   label: "Top Lumpsum Funds",
  //   description: "Top funds for one-time investments.",
  //   icon: <Payments style={{ fontSize: "2.4rem" }} />,
  //   path: "/collections/top-lumpsum-funds",
  // },
  {
    label: "Consistent Performing Funds",
    description: "Funds that have consistently performed well.",
    icon: <Recommend style={{ fontSize: "3rem" }} />,
    path: "/collections/consistent-performing-funds",
  },
  {
    label: "Large Cap",
    description: "Funds that are invested in Large cap companies",
    icon: <Apartment style={{ fontSize: "3rem" }} />,
    path: "/collections/large-cap",
  },
  {
    label: "Mid Cap",
    description: "Funds that are invested in Mid cap companies",
    icon: <Home style={{ fontSize: "3rem" }} />,
    path: "/collections/mid-cap",
  },
  {
    label: "Small Cap",
    description: "Funds that are invested in Small cap companies",
    icon: <Store style={{ fontSize: "3rem" }} />,
    path: "/collections/small-cap",
  },
];

export const TEMP_CATEGORIES = [
  "Childrens Fund",
  "Debt: Banking and PSU",
  "Debt: Corporate Bond",
  "Debt: Credit Risk",
  "Debt: Dynamic Bond",
  "Debt: Floater",
  "Debt: Gilt",
  "Debt: Gilt Fund with 10 year constant duration",
  "Debt: Liquid",
  "Debt: Long Duration",
  "Debt: Low Duration",
  "Debt: Medium Duration",
  "Debt: Medium to Long Duration",
  "Debt: Money Market",
  "Debt: Overnight",
  "Debt: Short Duration",
  "Debt: Ultra Short Duration",
  "Equity: Contra",
  "Equity: Dividend Yield",
  "Equity: ELSS",
  "Equity: Flexi Cap",
  "Equity: Focused",
  "Equity: Large and Mid Cap",
  "Equity: Large Cap",
  "Equity: Mid Cap",
  "Equity: Multi Cap",
  "Equity: Sectoral-Banking and Financial Services",
  "Equity: Sectoral-FMCG",
  "Equity: Sectoral-Infrastructure",
  "Equity: Sectoral-Pharma and Healthcare",
  "Equity: Sectoral-Technology",
  "Equity: Small Cap",
  "Equity: Thematic-Consumption",
  "Equity: Thematic-Energy",
  "Equity: Thematic-ESG",
  "Equity: Thematic-International",
  "Equity: Thematic-Manufacturing",
  "Equity: Thematic-MNC",
  "Equity: Thematic-Others",
  "Equity: Thematic-PSU",
  "Equity: Thematic-Quantitative",
  "Equity: Thematic-Transportation",
  "Equity: Value",
  "ETFs",
  "Fund of Funds-Domestic-Debt",
  "Fund of Funds-Domestic-Equity",
  "Fund of Funds-Domestic-Gold",
  "Fund of Funds-Domestic-Gold and Silver",
  "Fund of Funds-Domestic-Hybrid",
  "Fund of Funds-Domestic-Silver",
  "Fund of Funds-Overseas",
  "Hybrid: Aggressive",
  "Hybrid: Arbitrage",
  "Hybrid: Balanced",
  "Hybrid: Conservative",
  "Hybrid: Dynamic Asset Allocation",
  "Hybrid: Equity Savings",
  "Hybrid: Multi Asset Allocation",
  "Index Fund",
  "Retirement Fund",
];

export const TOP_PERFORMING_AMOUNTS = [
  1000, 2000, 3000, 5000, 10_000, 15_000, 20_0000, 25_000, 30_000, 35_000,
  40_000, 45_000, 50_0000,
];

export const TOP_PERFORMING_PERIODS = Array(25)
  .fill()
  .reduce((acc, _, i) => {
    acc.push(i + 1);
    return acc;
  }, []);
