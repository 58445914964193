import axios from "axios";

import store from "redux/store";

// DON'T FORGOT TO CHANGE THIS WHEN PUSHING TO PRODUCTION
// Before changing value, logout from the account in UI

const DEV_ENABLED = false;
const DEV_IPV4_ADDRESS = "localhost";

const baseURL = "https://api.wisebulls.com";

const baseDevURL = `http://${DEV_IPV4_ADDRESS}:8000`;

export const credentialAxios = axios.create({
  baseURL: DEV_ENABLED ? baseDevURL : baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

credentialAxios.interceptors.request.use(
  async (config) => {
    try {
      const token = store.getState().user.currentUser;
      if (token) {
        config.headers.Authorization = `token ${token}`;
        return config;
      } else {
        axios.Cancel("No token exists");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const publicAxios = axios.create({
  baseURL: DEV_ENABLED ? baseDevURL : baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
