import React from "react";

import {
  Bottom,
  Container,
  MainCont,
  ModalLogo,
  Top,
  WidthController,
} from "components/common/LoginRegisterContent/LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  StyledLink,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";

import useDocumentTitle from "hooks/useDocumentTitle";

import RegisterForm from "components/forms/RegisterForm";

const Register = ({ closeModal }) => {
  useDocumentTitle("Register - WiseBulls");

  return (
    <MainCont>
      <Container style={{}}>
        <>
          <Top>
            <StyledLink to="/">
              <ModalLogo src={logo} />
            </StyledLink>

            <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
              <HeadingPrimary color="#f7f8fa">
                Investing in{" "}
                <HeadingPrimaryBold color="#f7f8fa">
                  Mutual Funds simplified
                </HeadingPrimaryBold>
              </HeadingPrimary>
            </div>
          </Top>

          <Bottom>
            <WidthController>
              <HeadingCont></HeadingCont>

              <RegisterForm />
            </WidthController>
          </Bottom>
        </>
      </Container>
    </MainCont>
  );
};

export default Register;
