import React from "react";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import NomineeForm from "components/forms/NomineeForm";
import { useLocation } from "react-router-dom";

const AddNominee = () => {
  const location = useLocation();

  const fromLocation = location.state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-address";

  return (
    <>
      <RegistrationCapsule
        heading={`${fromLocation ? "Edit" : "Add"} Nominee`}
        para={`${fromLocation ? "Edit" : "Add"} Nominee for your account`}
        fromLocation={fromLocation}
      >
        <NomineeForm fromLocation={fromLocation} navigateTo={navigateTo} />
      </RegistrationCapsule>
    </>
  );
};

export default AddNominee;
